import React, { useState, useEffect, createRef } from 'react';
import { CircularProgress, Grid, Typography, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import makeStyles from './styles';
import PlaceDetails from '../PlaceDetails/PlaceDetails';

const List = ( { places, childClicked, isLoading, type, setType, rating, setRating }) => {
    const classes = makeStyles();
    const [elRefs, setElRefs] = useState([]);
    
    useEffect(() => {
        setElRefs((refs) => Array(places.length).fill().map((_, i) => refs[i] || createRef()));
      }, [places]);
    
    return (
        <div className={classes.container}>
            <Typography variant='h4'>Restaurants, hôtels et Divertissements autour de vous</Typography>
            {isLoading ? (
                <div className={classes.loading}>
                    <CircularProgress size="5rem" />
                </div>
            ) : (
                <>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="type">Type</InputLabel>
                        <Select id="type" value={type} onChange={(e) => setType(e.target.value)}>
                            <MenuItem value="restaurants">Restaurants</MenuItem>
                            <MenuItem value="hotels">Hôtels</MenuItem>
                            <MenuItem value="attractions">Attractions</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="rating">Notes</InputLabel>
                        <Select id="rating" value={rating} onChange={(e) => setRating(e.target.value)}>
                            <MenuItem value={0}>Tous</MenuItem>
                            <MenuItem value={3}>Au dessus de 3</MenuItem>
                            <MenuItem value={4}>Au dessus de 4</MenuItem>
                            <MenuItem value={4.5}>Au dessus de 4.5</MenuItem>
                        </Select>
                    </FormControl>
                    <Grid container spacing={3} className={classes.list}>
                        {places?.map((place, i) => (
                            <Grid item key={i} xs={12}>
                                <PlaceDetails 
                                place={place} 
                                selected={Number(childClicked) === i}
                                refProp={elRefs[i]}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default List;